import {getProductById, getProductRegionById} from "./productFunctions";

const editCart = (id, option, size, quantity, editFromCart = false) => {
    let currentCart = JSON.parse(localStorage.getItem('sec-cart'));

    localStorage.setItem('sec-expire', new Date().toString());

   if(currentCart?.length) {
       const isProductNew = currentCart.findIndex((item) => {
           return (item.id === id) && (item.size === size) && (item.option === option);
       }) === -1;

       if(isProductNew) {
           currentCart.push({
               id,
               option,
               size,
               quantity
           });
       }
       else {
           for(const item of currentCart) {
               if((item.id === id) && (item.size === size) && (item.option === option)) {
                   if(editFromCart) {
                       item.quantity = quantity;
                   }
                   else {
                       item.quantity += 1;
                   }
               }
           }
       }
   }
   else {
       currentCart = [{
           id,
           option,
           size,
           quantity
       }]
   }

   localStorage.setItem('sec-cart', JSON.stringify(currentCart));
}

const deleteFromCart = ({ uuid, id, size, option, banquet }) => {
    localStorage.setItem('sec-expire', new Date().toString());

    if(banquet) {
        let currentCart = JSON.parse(localStorage.getItem('sec-cart-banquet'));

        const newCart = [];
        currentCart.forEach((item) => {
            newCart.push(item.filter((itemChild) => {
                return uuid !== itemChild.uuid;
            }));
        });

        localStorage.setItem('sec-cart-banquet', JSON.stringify(newCart));
    }
    else {
        let currentCart = JSON.parse(localStorage.getItem('sec-cart'));

        const newCart = currentCart.filter((item) => {
            console.log(item.id, item.size, item.option);
            return item.id !== id || item.size !== size || item.option !== option;
        });

        if(newCart.length === 0) localStorage.removeItem('sec-cart');
        else localStorage.setItem('sec-cart', JSON.stringify(newCart));
    }
}

const calculatePrice = (size, option, quantity, prices) => {
    let price;
    if(size === "M") {
        if(option === "Mięsna" || option === 'Mieszana') price = prices.mMeat;
        else price = prices.mVege;
    }
    else {
        if(option === "Mięsna" || option === 'Mieszana') price = prices.lMeat;
        else price = prices.lVege;
    }

    price *= quantity;
    return price;
}

const addToCartBanquet = (cartInfo) => {
    if(localStorage.getItem('sec-cart-banquet')) {
        const currentCart = JSON.parse(localStorage.getItem('sec-cart-banquet'));
        currentCart.push(cartInfo);
        localStorage.setItem('sec-cart-banquet', JSON.stringify(currentCart));
    }
    else {
        localStorage.setItem('sec-cart-banquet', JSON.stringify([cartInfo]));
    }
}

const validateCartForWarsaw = async () => {
    let isCartOk = true;
    let currentCart = JSON.parse(localStorage.getItem('sec-cart'));

    for(const item of currentCart) {
        const product = await getProductRegionById(item.id);
        const result = product?.data;

        if(!result.warsaw) {
            isCartOk = false;
            break;
        }
    }

    return isCartOk;
}

const validateCartForPoland = async () => {
    let isCartOk = true;
    let currentCart = JSON.parse(localStorage.getItem('sec-cart'));

    for(const item of currentCart) {
        if(item.size !== 'L') {
            isCartOk = false;
            break;
        }

        const product = await getProductRegionById(item.id);
        const result = product?.data;

        if(!result.poland) {
            isCartOk = false;
            break;
        }
    }

    return isCartOk;
}

export { editCart, deleteFromCart, calculatePrice, addToCartBanquet, validateCartForWarsaw, validateCartForPoland }
